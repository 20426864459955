import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { IAdv } from "../interfaces/Adv";
import './JobDescription.css';

export default function JobDescription() {
    const [jobs, setJobs] = useState<IAdv | null>(null);
    const params = useParams();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const title = searchParams.get('title');
        if (title) document.title = title;
    }, [searchParams]);
    const qsLocation = useMemo(() => {
        const qLocation = searchParams.get('location');
        if (qLocation) return qLocation;
        return "";
    }, [searchParams]);
    const token = "7Mfhd8EDBpX";

    const jobReference = useMemo(() => {
        return params.jobReference ?? '';
    }, [params]);

    const selectedJob = useMemo(() => {
        if (jobs) {
            const job = jobs.job.find(j => j.reference === jobReference);
            if (job) return job;
        }
        return null;
    }, [jobReference, jobs]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await fetch('https://app.recruitwizard.com/api/adverts/'+ token + '/json');
                const data = (await res.json()) as IAdv;
                setJobs(data);
            } catch (error) {
            }
        };
        getData();
    }, [token]);

    if (selectedJob) {
        return (
            <div className="container">
                <Link className="btn btn-primary" to={`/?location=${qsLocation}`}>Back to Job Listings</Link>
                {" | "}
                <a className="btn btn-primary" href={selectedJob.links.applicationFormUrl} target="_blank" rel="noreferrer noopener">Apply!</a>
                <hr />
                <h2>{selectedJob.title}</h2>
                <hr />
                <h4>{selectedJob.summary}</h4>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: selectedJob.description }} />
                <a className="btn btn-primary" href={selectedJob.links.applicationFormUrl} target="_blank" rel="noreferrer noopener">Apply!</a>
                <br /><br /><br />
            </div>
        );
    }

    return <></>;
}