import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { IAdv } from '../interfaces/Adv';
import { IClassification, ISalary } from '../interfaces/job';
import NttLogoHorizontal from '../Assets/img/ntt-h.png';
import './JobsWidget.css';

const RenderBulletPoints = (list: any[]) => {
    return (
        <ul className="bullet-points">
            {list.length > 0 &&
                list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                })
            }
        </ul>
    );
};

const RenderBadges = (classification: IClassification[], salary: ISalary) => {
    let locationText = '';
    let workTypeText = '';

    const location = classification.find(c => c.name.toLowerCase() === 'location');
    if (location) locationText = `${location.name}: ${location.text}`;

    const workType = classification.find(c => c.name.toLowerCase() === 'work type');
    if (workType) workTypeText = `${workType.name}: ${workType.text}`;

    const badgeClass = "badge badge-pill badge-light"
    if (salary.text) {
        return (
            <>
                <span className={badgeClass}>{`${salary.text}`}</span>{'\u00a0'}
                <span className={badgeClass}>{locationText}</span>{'\u00a0'}
                <span className={badgeClass}>{workTypeText}</span>
            </>
        );
    }
    else {
        return (
            <>
                <span className={badgeClass}>{locationText}</span>{'\u00a0'}
                <span className={badgeClass}>{workTypeText}</span>
            </>
        );
    }
};


const GetJobClass = (classification: IClassification[], qsLocation: String ) => {
    var jobClass = "list-group-item"
    if (qsLocation && qsLocation !== "All") {
        const location = classification.find(c => c.name.toLowerCase() === 'location');
        if (location) {
            if (location.text !== qsLocation) {
                jobClass = "hide-list-group-item"
            }
        }
        else {
            jobClass = "hide-list-group-item" 
        }
    }
    return jobClass;
};

const GetLocationClass = (btnLocation: String, qsLocation: String) => {
    var locationClass = "btn btn-primary btn-search-ntt";
    if (qsLocation) {
        if (qsLocation === btnLocation) {
            locationClass = "btn btn-primary btn-search-ntt-selected";
        }
    }
    else {
        if (btnLocation === "All") {
            locationClass = "btn btn-primary btn-search-ntt-selected";
        }
    }
    return locationClass;
};
export default function JobsWidget() {
    const [jobs, setJobs] = useState<IAdv | null>(null);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const qsLocation = useMemo(() => {
        const qLocation = searchParams.get('location');
        if (qLocation) return qLocation;
        return "All";
    }, [searchParams]);
    const token = "7Mfhd8EDBpX";
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await fetch('https://app.recruitwizard.com/api/adverts/'+ token + '/json');
                const data = (await res.json()) as IAdv;
                setJobs(data);
            } catch (error) {                
            }
        };
        getData();
    }, [token]);

    return (
        <div className="container" style={{ marginTop: 190 }}>
            <div className="btn-container">
                <div><img src={NttLogoHorizontal} alt="logo" height={150} /></div>
                <div style={{ padding: '15px 0' }}>
                    <Link className={GetLocationClass("All", qsLocation)} to={`?location=All`}>All Locations</Link>
                    <Link className={GetLocationClass("Melbourne", qsLocation)} to={`?location=Melbourne`}>Melbourne</Link>
                    <Link className={GetLocationClass("Sydney", qsLocation)} to={`?location=Sydney`}>Sydney</Link>
                    <Link className={GetLocationClass("Brisbane", qsLocation)} to={`?location=Brisbane`}>Brisbane</Link>
                    <Link className={GetLocationClass("Perth", qsLocation)} to={`?location=Perth`}>Perth</Link>
                    <Link className={GetLocationClass("Adelaide", qsLocation)} to={`?location=Adelaide`}>Adelaide</Link>
                    <Link className={GetLocationClass("ACT", qsLocation)} to={`?location=ACT`}>ACT</Link>
                </div>
            </div>
            <ul className="list-group" >
                {jobs &&
                    jobs.job.map((item, index) => (
                        
                        <li key={index} className={GetJobClass(item.classifications.classification, qsLocation)}>
                            <h4>{item.title}</h4>
                            <p>{item.summary}</p>
                            {RenderBulletPoints(item.bulletPoints.bulletPoint)}
                            <br />
                            {RenderBadges(item.classifications.classification, item.salary)}
                            <br />
                            <br />
                            <Link className="btn btn-primary" to={`/${encodeURIComponent(item.reference)}?title=${encodeURIComponent(item.title)}&location=${qsLocation}`}> Learn More</Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}