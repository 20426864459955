import React from 'react';
import JobsWidget from './components/JobsWidget';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import JobDescription from './components/JobDescription';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <JobsWidget /> } />
        <Route path='/:jobReference' element={ <JobDescription /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
